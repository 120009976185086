.hr-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cecece;
  line-height: 0.1em;
  margin: 2rem 0 2rem;
  font-size: 1rem;
}

.hr-text span {
  background: #f2f2f2;
  padding: 0 10px;
  color: #bebebe;
  font-size: 0.8rem;
}
