/*HEXAGON GRID*/
svg {
}
svg g {
  fill: #548eb3;
  fill-opacity: 1;
}
svg g:active {
  fill: #ffffff;
}
svg g.grey g {
  fill: #c4c4c4;
  fill-opacity: 1;
}
svg g.darkgrey g {
  fill: #888888;
  fill-opacity: 1;
}
svg g:hover {
  fill: #548eb3;
  fill-opacity: 0.7;
}
svg g:hover text {
  fill-opacity: 1;
}

svg g polygon {
  stroke: #548eb3;
  stroke-width: 0.2;
  transition: fill-opacity 0.5s;
  cursor: pointer;
}
svg g.grey g polygon {
  stroke: #c4c4c4;
  /* stroke-width: 0.2; */
  /* transition: fill-opacity 0.5s; */
  cursor: default;
}
svg g.darkgrey g polygon {
  stroke: #888888;
  /* stroke-width: 0.2; */
  /* transition: fill-opacity 0.5s; */
  cursor: default;
}
svg g text {
  font-size: 0.19em;
  font-weight: bold;
  fill: white;
  cursor: pointer;
}
svg path {
  fill: none;
  stroke: #7be3f6;
  stroke-width: 0.2em;
  stroke-opacity: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}

svg image {
  /* filter: invert(1); */
  cursor: pointer;
}
