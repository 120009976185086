body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
  background-color: white !important;
}

.menu-dialog-xl > .menu-dialog {
  max-width: 90% !important;
}

.text-wrap-ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.reset-password-modal .modal-content {
  box-shadow: 0.5rem 0.5rem 1rem rgba(60, 146, 181, 0.5);
}

.btn-tab {
  border-radius: unset !important;
  border: unset !important;
  box-shadow: none;
}
.btn-tab:hover {
  background-color: var(--bs-primary) !important;
  color: white !important;
}
.btn-tab:focus {
  box-shadow: 0px 2px 4px #888 !important;
}

.btn-tab-active {
  background-color: white !important;
  color: black !important;
  font-weight: 600 !important;
  box-shadow: 0px 2px 4px #888;
}

.action-menu-dropdown {
  box-shadow: 0px 2px 4px #888;
}

.action-menu-dropdown:hover {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.no-shadow-button.btn-secondary:focus {
  box-shadow: unset;
}
.no-padding-button.btn {
  padding: 0 0 0 0;
}

.table-column {
  padding: 0.375rem 0.75rem;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.table-item {
  padding: 0.375rem 0.75rem;
}

.z-1 {
  z-index: 1;
}

.no-hover {
  pointer-events: none;
}
.m-015 {
  margin: 0.15rem;
}
.disabled-pointer {
  pointer-events: none;
  opacity: 0.5;
}
