table th.main {
  font-size: 1.2rem;
}

table tbody tr {
  height: 4rem;
}

.menu-dialog .modal-content {
  background-color: var(--bs-light);
}
@media screen and (min-width: 576px) {
  .menu-dialog .modal-dialog {
    min-width: 70rem;
  }
}
