.warning {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: lightyellow;
  border-radius: 5px;
  border: 1px solid black;
}

.osmlayer {
  text-align: left;
  line-height: 18px;
  color: #555;
}

#label_red {
  font-weight: bold;
  color: red;
}
