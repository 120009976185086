.bg_gray {
  background-color: var(--bs-gray-300);
  cursor: pointer;
}
.bg_green {
  background-color: #d9ffe3;
  cursor: pointer;
  min-width: 112px;
  align-items: center;
  justify-content: center;
}
.bg_error {
  background-color: white;
  color: #dc3545;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: dashed 2px #dc3545 !important;
}

.custom_dropzone {
  border: dashed 2px #c8c8c8 !important;
  border-radius: var(--bs-border-radius);
  padding: 0.35rem;
  flex: 1;
  width: 100%;
  background-color: white;
  font-size: 1.2rem;
}

.card_progressing {
  border: none !important;
  align-items: center;
  box-shadow: unset;
  background-color: var(--bs-gray-300);
  text-decoration: none !important;
}
