.read_only {
  background-color: transparent !important;
  border: 1px solid lightgray !important;
  background-image: #e9ecef !important;
  padding: 0 !important;
}

.read_only::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: transparent;
  opacity: 0; /* Firefox */
}

.read_only::-ms-input-placeholder {
  /* Microsoft Edge */
  color: transparent;
}

.read_only > div {
  background-color: #e9ecef !important;
  border: none !important;
  background-image: none !important;
  padding: 0 !important;
}
