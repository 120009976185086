.date-filter-input {
  min-height: 2rem;
  min-width: 14rem;
  border: 1px solid lightgray;
  padding: 0.37rem;
}

.date-filter-input:hover {
  border: 1px solid grey;
}

.date-filter-input-focus {
  border: solid 1px var(--bs-primary) !important;
  box-shadow: inset 0 1px 1px var(--bs-light), 0 0 8px var(--bs-primary);
}

.date-filter {
  position: relative;
  display: inline-block;
}

.date-filter-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid lightgray;
  margin-top: 2px;
  z-index: 1;
}
.date-filter-menu .list {
  min-width: 14rem;
}
.date-filter-menu-item {
  padding: 0.5rem;
  border: 1px solid lightgray;
}
.date-filter-menu-item.selected {
  background-color: var(--bs-primary);
  color: white;
}
.date-filter-menu .custom-filter {
  min-width: 10rem;
}
